@import '../colors';

.dx-popup-title {
    padding: 0px;
    background-color: $secondary-200;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $primary;
        margin-bottom: 0px;
    }
}

.remove-container-padding {
    .dx-popup-content {
        padding: 0;
    }

    .dx-overlay-content {
        padding: 0;
    }
}

.popup-footer {
    background-color: $primary-600;
    color: white;
}


// .dx-popup-bottom.dx-toolbar {
//     padding: 0px;
//     background-color: $primary-600;
//     color: white;

//     &.dx-popup-cancel {
//         background-color: transparent;
//     }

//     .dx-toolbar-items-container {
//         height: 34px;
//     }

// }

.dx-popup-bottom.dx-dialog-buttons {
    background-color: transparent;
}