@import '../colors';

hemi-card {
    //padding: .25rem;
    // border: 1px solid $primary-500;
    border-radius: 5px;

    // background-color: $primary-100;
    .default-border {
        border: $primary-100 1px 1px 0px 0px solid;
    }

    .row.input-fields {
        border: $primary-100 1px solid;

        .col,
        .col-1,
        .col-2,
        .col-3,
        .col-4,
        .col-5,
        .col-6,
        .col-7,
        .col-8,
        .col-9,
        .col-10,
        .col-11,
        .col-12 {
            border: $primary-100 1px solid;
        }

        .dx-texteditor.dx-editor-filled {
            background-color: $white;
        }

    }

    .hemi-card-header {
        border-radius: 5px 5px 0px 0px;
        background-color: $primary-600;
        color: white;
        padding: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        b {
            letter-spacing: 1px;
            font-weight: 600;
        }
    }

    .hemi-card-header-case {
        border-radius: 5px 5px 0px 0px;
        background-color: $success-700;
        color: white;
        padding: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        b {
            letter-spacing: 1px;
            font-weight: 600;
        }
    }
}