@import '../colors';


// .grey-datagrid-background {
//     .dx-datagrid {
//         background-color: $gray-100;
//     }
// }

.dx-datagrid {
    .dx-datagrid-header-panel {
        padding: 0px 8px;
        height: 50px;
        background-color: $primary-700;
        border-color: transparent;

        .dx-toolbar-items-container {
            background-color: $primary-700;
            color: $white;
            height: 50px;

            .dx-item .dx-toolbar-item {
                height: 42px;
            }

            .dx-textbox.dx-texteditor,
            .dx-numberbox.dx-texteditor,
            .dx-selectbox-container {
                height: 42px !important;

                .dx-tag {
                    margin: 4px 4px 0px 0px;
                }
            }

            .dx-datagrid-search-panel {
                height: 42px;
                margin: 0px;
            }

            .dx-button-text,
            .dx-icon {
                color: $white;
            }

            .dx-show-clear-button .dx-icon-clear {
                color: #bcbcbc;
            }

            .dx-datebox-calendar.dx-dropdowneditor-active.dx-editor-filled {
                background-color: #f8f8f8;
            }
        }
    }

    .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending).dx-command-edit.dx-command-edit-with-icons .dx-link,
    .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-datagrid-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-command-edit.dx-command-edit-with-icons .dx-link,
    .dx-datagrid-content .dx-datagrid-table .dx-row>td:not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-command-edit.dx-command-edit-with-icons .dx-link {
        width: 20px;
        height: 20px;
        border-radius: 3px;
        font-size: 13px;
        line-height: 17px;
    }

    .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link,
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row>td:not(.dx-focused),
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row>tr>td:not(.dx-focused) {
        background-color: $primary-100;
        color: $gray-500;
    }

    .dx-link.dx-link-edit.dx-icon-edit,
    .dx-row-focused .dx-command-edit-with-icons .dx-link.dx-link-edit.dx-icon-edit,
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link.dx-link-edit {
        background-color: $warning-500;
        border: 1px solid $warning-700;
        color: $gray-700;

        &::before {
            content: "\f304";
            font-family: 'Font Awesome 6 Pro';
            font-style: normal;
            font-weight: 300;
            font-display: block;
        }
    }

    .dx-link.dx-link-delete,
    .dx-row-focused .dx-command-edit-with-icons .dx-link.dx-link-delete,
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link.dx-link-delete {
        background-color: $danger-400;
        border: 1px solid $danger-700;
        color: $white;

        &::before {
            content: "\f2ed";
            font-family: 'Font Awesome 6 Pro';
            font-style: normal;
            font-weight: 300;
            font-display: block;
        }
    }

    .dx-link.dx-link-save,
    .dx-row-focused .dx-command-edit-with-icons .dx-link.dx-link-save,
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link.dx-link-save {
        background-color: $success-500;
        border: 1px solid $success-700;
        color: $white;

        &::before {
            content: "\f0c7";
            font-family: 'Font Awesome 6 Pro';
            font-style: normal;
            font-weight: 300;
            font-display: block;
        }
    }

    .dx-link.dx-link-cancel,
    .dx-row-focused .dx-command-edit-with-icons .dx-link.dx-link-cancel,
    .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link.dx-link-cancel {
        background-color: $white;
        border: 1px solid $gray-600;
        color: $gray-400;

        &::before {
            content: "\f00d";
            font-family: 'Font Awesome 6 Pro';
            font-style: normal;
            font-weight: 300;
            font-display: block;
        }
    }

    .dx-datagrid-pager.dx-pager {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.dx-datagrid-rowsview .dx-master-detail-row>.dx-master-detail-cell {
    padding: 0;
}

// .dx-datagrid .dx-column-indicators {
//     display: none;
// }

.dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
    background-color: $warning-100
}

.dx-datagrid>.dx-datagrid-headers {
    background-color: $secondary-100;
}