@import '../colors';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import '../vendor/bootstrapTheme';

.dx-button-text {
  text-transform: none;
  font-weight: 400;
}


// .dx-button-mode-text.dx-button-default.dx-state-focused .dx-icon {
//   color: $white;
// }

.dx-button.submit,
.dx-button.success-btn {
  background-color: $success-500;
  border: 1px solid $success-700;

  &.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 3px;
  }

  .dx-button-content {
    padding: 0px;

    i {
      color: $white;
    }
  }

  .dx-button-text {
    padding: 8px;
    color: $white;
  }
}


.dx-button.purple-btn {
  background-color: $purple-1100;
  border: 1px solid $purple-1100;

  &.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 3px;
  }

  .dx-button-content {
    padding: 0px;

    i {
      color: $white;
    }
  }

  .dx-button-text {
    padding: 8px;
    color: $white;
  }
}

.dx-button.close,
.dx-button.white-btn {
  background-color: $white;
  border: 1px solid $gray-400;

  &.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 3px;
  }

  .dx-button-content {
    padding: 0px;

    i {
      color: $gray-600;
    }
  }

  .dx-button-text {
    padding: 8px;
    color: $gray-600;
  }
}

.dx-button.edit,
.dx-button.warning-btn {
  background-color: $warning-500;
  border: 1px solid $warning-700;

  &.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 3px;
  }

  .dx-button-content {
    padding: 0px;

    i {
      color: $gray-600;
    }
  }

  .dx-button-text {
    padding: 8px;
    color: $gray-600;
  }
}

.dx-button.warning-dark-btn {
  background-color: $warning-700;
  border: 1px solid $warning-800;

  &.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 3px;
  }

  .dx-button-content {
    padding: 0px;

    i {
      color: $white;
    }
  }

  .dx-button-text {
    padding: 8px;
    color: $white;
  }
}

.dx-button.delete,
.dx-button.danger-btn {
  background-color: $danger-400;
  border: 1px solid $danger-700;


  &.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 3px;
  }

  .dx-button-content {
    padding: 0px;

    i {
      color: $white;
    }
  }

  .dx-button-text {
    padding: 8px;
    color: $white;
  }
}

.dx-button.create,
.dx-button.primary-btn {
  background-color: $primary-400;
  border: 1px solid $primary-700;

  &.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 3px;
  }

  .dx-button-content {
    padding: 0px;

    i {
      color: $white;
    }
  }

  .dx-button-text {
    padding: 8px;
    color: $white;
  }
}

.dx-button.gray-400,
.dx-button.gray-btn {
  background-color: $gray-400;
  border: 1px solid $gray-600;


  &.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 3px;
  }

  .dx-button-content {
    padding: 0px;

    i {
      color: $white;
    }
  }

  .dx-button-text {
    padding: 8px;
    color: $white;
  }
}

.dx-button.gray-dark-btn {
  background-color: $gray-500;
  border: 1px solid $gray-600;


  &.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 3px;
  }

  .dx-button-content {
    padding: 0px;

    i {
      color: $white;
    }
  }
}

.dx-button.success-300-btn {
  background-color: $success-300;
  border: 1px solid $gray-600;


  &.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 3px;
  }

  .dx-button-content {
    padding: 0px;

    i {
      color: $gray-600;
    }
  }

  .dx-button-text {
    padding: 8px;
    color: $white;
  }
}

.dx-button.secondary-btn {
  background-color: $secondary-600;
  border: 1px solid $secondary-800;

  &.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 3px;
  }

  .dx-button-content {
    padding: 0px;

    i {
      color: $white;
    }
  }

  .dx-button-text {
    padding: 8px;
    color: $white;
  }
}

.dx-button.menu {
  background-color: $primary-300;
  border: 1px solid $primary-500;

  &.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 3px;
  }

  .dx-button-content {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: $white;
    }
  }
}

.change-button-purple {
  .dx-button-content {
    background-color: $purple-500;
  }
}

.change-button-green {
  .dx-button-content {
    background-color: $success;
  }
}

.change-button-grey {
  .dx-button-content {
    background-color: $gray-400;
  }
}

.change-button-secondary {
  .dx-button-content {
    background-color: $secondary-600;
  }
}

.change-button-warning {
  .dx-button.dx-button-default .dx-icon {
    color: $gray-900;
  }

  .dx-button-content {
    background-color: $warning-500;
  }
}

.change-button-danger {
  .dx-button.dx-button-default .dx-icon {
    color: $white;
  }

  .dx-button-content {
    background-color: $danger-400;
  }
}

.change-button-warning-fa-small {
  .dx-button.dx-button-default .dx-icon {
    background-color: $warning-500;
    font-size: small;
    border: 1px solid $warning-700;
    color: $gray-900;
  }

  .dx-button-mode-outlined.dx-button-default {
    border: none;
  }

}

.change-button-danger-fa-small {
  .dx-button.dx-button-default .dx-icon {
    padding: 0px;
    background-color: $danger-400;
    font-size: small;
    border: 1px solid $danger-700;
    color: $white;
  }

  .dx-button-mode-outlined.dx-button-default {
    border: none;
  }
}

.change-button-primary-fa-small {
  .dx-button.dx-button-default .dx-icon {
    background-color: $primary;
    font-size: small;
    border: 1px solid $primary-700;
    color: $white;

  }

  .dx-button-mode-outlined.dx-button-default {
    border: none;
  }
}

.change-button-success-fa-small {
  .dx-button.dx-button-default .dx-icon {
    background-color: $success;
    font-size: small;
    border: 1px solid $success-700;
    color: $white;

  }

  .dx-button-mode-outlined.dx-button-default {
    border: none;
  }

}

.btn-small.dx-button {
  height: 20px;
  width: 20px;
  min-width: 20px;

  .dx-icon {
    font-size: small;
  }

  &.dx-button-mode-text.dx-button-has-icon {
    .dx-button-content {
      padding: 0px;
    }
  }

}

.change-button-white-fa-small {
  .dx-button.dx-button-default .dx-icon {
    background-color: $white;
    font-size: small;
    border: 1px solid $gray-600;
    color: $gray-400;

  }

  .dx-button-mode-outlined.dx-button-default {
    border: none;
  }

}

.change-button-primary-400 {
  .dx-button.dx-button-default .dx-icon {
    color: $white;
  }

  .dx-button-content {
    background-color: $primary-400;
  }
}


@each $color,
$value in $theme-colors {
  .dx-button.#{$color} {
    background-color: $value;

    &.dx-button-mode-text {
      background-color: transparent;
      color: $value;

      .dx-icon {
        color: $value;
      }
    }

    &.dx-button-mode-contained.text-#{$color} .dx-icon {
      color: $value
    }

    &.dx-button-mode-contained .dx-button-text {
      color: color-contrast($value)
    }

    &.dx-button-has-icon .dx-icon :not(.dx-button-mode-text) {
      color: color-contrast($value);
    }

  }

  .dx-checkbox {
    &.border-#{$color} .dx-checkbox-icon {
      border: 2px solid $value;
    }

    &.dx-checkbox-checked.#{$color} .dx-checkbox-icon {
      background-color: $value;
      border: none;
    }

    &.dx-checkbox-indeterminate.#{$color} .dx-checkbox-icon {
      background-color: $value;
      border: none;
    }
  }


}