@import '../colors';

.dx-widget {
  font-size: 14px;
  line-height: 18.2px;
}

.dx-tabpanel {

  .dx-tabs {
    background-color: $primary-300;
  }

  .dx-tabpanel-tab {
    flex-grow: 1;
  }

  .dx-tabs-nav-button.dx-button.dx-tabs-nav-button.dx-button-has-icon:not(.dx-button-has-text) .dx-icon {
    color: $white;
  }

  .dx-tab {
    height: 46px;
    color: $white;
    background-color: $primary-300;
    font-weight: 400;

    .dx-tab-text {
      font-weight: 400;
    }

    &.dx-tab-selected {
      background-color: $primary-500;

      &::before {
        background-color: $primary-300;
      }

      .dx-tab-text {
        font-weight: 600;
      }
    }

    &.dx-state-hover {
      background-color: $primary-300;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        height: 2px;
        left: 0;
        right: 0;
        background-color: $white;
      }

      .dx-tab-text {
        font-weight: 600;
      }
    }
  }
}

.dx-tabs.dx-state-focused .dx-tab.dx-state-focused {
  background-color: $primary-500;
}

.dx-drawer .dx-overlay-content {

  .dx-list {
    .dx-item.dx-list-item.dx-state-hover {
      background-color: $secondary-100;
    }
  }
}

.red-checkbox {
  .dx-checkbox-icon {
    background-color: $white;
  }

  .dx-checkbox-checked .dx-checkbox-icon {
    background-color: $danger-400;
  }
}

.green-checkbox {
  .dx-checkbox-icon {
    background-color: $white;
  }

  .dx-checkbox-checked .dx-checkbox-icon {
    background-color: $success-500;
  }
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
  background-color: rgba(0, 0, 0, .04);
  color: #474747;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active,
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active .dx-list-slide-item-content {
  background-color: #ccc;
}

.dx-editor-filled.dx-texteditor-with-floating-label .dx-texteditor-label,
.dx-editor-filled.dx-texteditor-with-floating-label.dx-state-focused .dx-texteditor-label,
.dx-editor-filled.dx-texteditor-with-label .dx-texteditor-label,
.dx-editor-filled.dx-texteditor-with-floating-label.dx-dropdowneditor-active .dx-texteditor-label,
.dx-editor-filled.dx-texteditor-with-floating-label.dx-state-focused .dx-texteditor-label {
  top: 4px;
  font-weight: bolder;
  text-transform: uppercase;
  color: #474747;
}