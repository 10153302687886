@use '../colors' as color;

$theme-colors: (
    "primary": color.$primary,
    "primary-600": color.$primary-600,
    "primary-500": color.$primary-500,
    "primary-300": color.$primary-300,
    "primary-200": color.$primary-200,
    "primary-100": color.$primary-100,
    "primary-light": color.$primary-100,
    "primary-dark": color.$primary-700,
    "warning": color.$warning,
    "warning-light": color.$warning-100,
    "warning-dark": color.$warning-800,
    "warning-100": color.$warning-100,
    "warning-200": color.$warning-200,
    "warning-300": color.$warning-300,
    "warning-400": color.$warning-400,
    "warning-600": color.$warning-600,
    "warning-700": color.$warning-700,
    "warning-800": color.$warning-800,
    "danger": color.$danger,
    "danger-light": color.$danger-100,
    "danger-100": color.$danger-100,
    "danger-200": color.$danger-200,
    "danger-300": color.$danger-300,
    "danger-400": color.$danger-400,
    "danger-500": color.$danger-500,
    "danger-700": color.$danger-700,
    "danger-dark": color.$danger-700,
    "success": color.$success,
    "success-100": color.$success-100,
    "success-200": color.$success-200,
    "success-600": color.$success-600,
    "success-light": color.$success-100,
    "success-dark": color.$success-700,
    "secondary": color.$secondary,
    "secondary-light": color.$secondary-100,
    "secondary-100": color.$secondary-100,
    "secondary-200": color.$secondary-200,
    "secondary-dark": color.$secondary-700,
    "secondary-600": color.$secondary-600,
    "secondary-700": color.$secondary-700,
    "royal": color.$royal,
    "royal-light": color.$purple-100,
    "royal-dark": color.$purple-700,
    "info": color.$gray-600,
    "info-light": $gray-100,
    "info-dark": $gray-700,
    "light": color.$gray-100,
    "dark": color.$gray-900,
    "gray":color.$gray,
    "gray-light": color.$gray-100,
    "gray-200": color.$gray-200,
    "gray-300": color.$gray-300,
    "gray-500": color.$gray-500,
    "gray-400": color.$gray-400,
    "gray-600": color.$gray-600,
    "white": color.$white,
    "black": color.$black,
    "purple": color.$purple-500
);