@import '../colors';

.dx-texteditor {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  &.dx-editor-filled {
    background-color: #f8f8f8;

    &.dx-state-hover {
      background-color: #f8f8f8;
    }
  }

  &.dx-editor-filled::after {
    border-bottom: none;
  }
}

.dx-texteditor.dx-editor-outlined.dx-state-disabled .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined.dx-state-readonly .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined.dx-state-readonly.dx-state-hover .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined.dx-state-readonly .dx-texteditor-buttons-container,
.dx-texteditor.dx-editor-outlined.dx-state-readonly .dx-texteditor-buttons-container .dx-button-content {
  color: #474747;
  background-color: $disabled;
}


.dx-texteditor.dx-editor-filled.dx-state-disabled .dx-texteditor-input,
.dx-texteditor.dx-editor-filled.dx-state-readonly .dx-texteditor-input,
.dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover .dx-texteditor-input,
.dx-texteditor.dx-editor-filled.dx-state-readonly .dx-texteditor-buttons-container,
.dx-texteditor.dx-editor-filled.dx-state-readonly .dx-texteditor-buttons-container .dx-button-content
{
  color: #474747;
  background-color: $disabled;
}

.dx-texteditor.dx-editor-filled.dx-state-readonly .dx-tag-container
{
  background-color: $disabled;
} 

.outlined-input .dx-texteditor.dx-editor-filled.dx-state-readonly .dx-texteditor-label .dx-label {
  background-color: $disabled;
  top: 4px;
  font-weight: bolder;
  color: #474747;
}

.remove-radio-y-margin.dx-radiogroup .dx-radiobutton {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.remove-l-input-padding {
  .dx-placeholder::before {
    padding-left: 0 !important;
  }

  .dx-texteditor-input {
    padding-left: 0 !important;
  }
}

.remove-r-input-padding .dx-texteditor-input {
  .dx-placeholder::before {
    padding-right: 0 !important;
  }

  .dx-texteditor-input {
    padding-right: 0 !important;
  }
}

.remove-x-input-padding {
  .dx-placeholder::before {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .dx-texteditor-input {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.transparent-input {
  &.dx-selectbox {
    cursor: auto;
  }

  &.dx-texteditor,
  &.dx-texteditor.dx-state-hover,
  &.dx-texteditor.dx-state-active,
  &.dx-texteditor.dx-state-focused,
  &.dx-texteditor::after,
  &.dx-texteditor::before {
    background-color: transparent;
    border: none;
    transition: none;
    transform: none;
  }
}

.bg-input-success {
  .dx-texteditor.dx-editor-filled {
    background-color: $success-200;
  }
}

.bg-input-danger {
  .dx-texteditor.dx-editor-filled {
    background-color: $danger-100;
  }
}

.dx-editor-outlined {

  &.dx-texteditor-with-floating-label,
  &.dx-editor-outlined.dx-texteditor-with-label {
    margin-top: 0px;
  }

  &::after {
    border-bottom: none !important;
  }

  &.dx-texteditor.dx-state-active::before,
  &.dx-texteditor.dx-state-focused::before {
    // background-color: $white;
    border-bottom: none !important;
  }

  &.dx-texteditor-container {
    border: 1px solid $primary-300;
    border-radius: 5px;
  }
}

.outlined-input {
  .dx-texteditor {
    border-radius: 5px;
  }

  .dx-texteditor-label {
    .dx-label {
      top: 4px;
      font-weight: bolder;
      background-color: $white;
      color: #474747;
    }
  }

  .dx-texteditor.dx-editor-filled::after {
    background-color: $white;
    border-bottom: none !important;
  }


  .dx-texteditor.dx-state-active::before,
  .dx-texteditor.dx-state-focused::before {
    background-color: $white;
    border-bottom: none !important;
  }

  .dx-texteditor-container {
    text-transform: uppercase;
    font-weight: bolder;
    color: #474747;
    border: 1px solid $primary-300;
    border-radius: 5px;
  }
}

hemi-phone.border-primary-300.rounded-1 {
  dx-drop-down-box.dx-text-editor {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .dx-texteditor.dx-editor-filled::after,
  .dx-texteditor.dx-editor-filled::before {
    border-bottom: none !important;
  }


}

// .remove-bottom-border-on-filled-input {
//   .dx-texteditor.dx-editor-filled::after {
//     border-bottom: none !important;
//   }

//   .dx-texteditor.dx-state-active::before,
//   .dx-texteditor.dx-state-focused::before {
//     border-bottom: none !important;
//   }

//   .dx-texteditor-input {
//     background-color: white;
//   }
// }


.dx-datebox-calendar.dx-dropdowneditor-active.dx-editor-filled {
  background-color: #f8f8f8;
}


.dx-dropdownbox.iti__country.iti__standard {
  border-right: 0 !important;
}

.dx-texteditor-buttons-container {
  .dx-button-has-icon {
    i {
      font-size: 14px;
    }
  }
}

.dx-tagbox.dx-editor-filled.dx-texteditor-with-floating-label .dx-placeholder::before,
.dx-tagbox.dx-editor-filled.dx-texteditor-with-floating-label .dx-tag-container,
.dx-tagbox.dx-editor-filled.dx-texteditor-with-label:not(.dx-texteditor-label-outside) .dx-placeholder::before,
.dx-tagbox.dx-editor-filled.dx-texteditor-with-label:not(.dx-texteditor-label-outside) .dx-tag-container,
.dx-tagbox.dx-editor-underlined.dx-texteditor-with-floating-label .dx-placeholder::before,
.dx-tagbox.dx-editor-underlined.dx-texteditor-with-floating-label .dx-tag-container,
.dx-tagbox.dx-editor-underlined.dx-texteditor-with-label:not(.dx-texteditor-label-outside) .dx-placeholder::before,
.dx-tagbox.dx-editor-underlined.dx-texteditor-with-label:not(.dx-texteditor-label-outside) .dx-tag-container {
  padding-top: 16px;
  padding-bottom: 4px;
}

.dx-htmleditor.dx-htmleditor-outlined .dx-htmleditor-content {
  margin-inline-start: 1px;
}