@import './dx.material.echgrg-theme.css';
@import './colors';
@import './custom/headers';
@import './custom/buttons';
@import './custom/inputs';
@import './custom/toast';
@import './custom/datagrid';
@import './custom/containers';
@import './custom/dxCustom';
@import './custom/disabled';
@import './custom/popup';
@import './custom/card';

@import 'fontawesome';
@import "node_modules/@mdi/font/scss/materialdesignicons.scss";