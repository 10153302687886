.container-fluid {
    padding-right: var(--bs-gutter-x, 2rem);
    padding-left: var(--bs-gutter-x, 2rem);
}

.container-compact {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 960px) and (max-width: 1279.98px) {
        max-width: 80%;
    }

    @media only screen and (min-width: 1280px) {
        max-width: 60%;
    }
}