$white: #fff;
$black: #000;
$input-box: #fff;

$gray-100: #ebebeb;
$gray-200: #c2c2c2;
$gray-300: #999999;
$gray-400: #707070;
$gray-500: #474747;
$gray-600: #292929;
$gray-700: #202020;
$gray-800: #171717;
$gray-900: $black;
$gray: $gray-500;
$disabled: #E7E7E7;

$secondary-100: #ecf5f4;
$secondary-200: #d9eae9;
$secondary-300: #c6e0de;
$secondary-400: #b3d5d3;
$secondary-500: #a0cbc8;
$secondary-600: #80a2a0;
$secondary-700: #607a78;
$secondary-800: #405150;
$secondary-900: #202928;
$secondary: $secondary-700;

$primary-100: #d1dfe6;
$primary-200: #a4bfcd;
$primary-300: #769eb5;
$primary-400: #497e9c;
$primary-500: #1b5e83;
$primary-600: #164b69;
$primary-700: #10384f;
$primary-800: #0b2634;
$primary-900: #05131a;
$primary: $primary-500;

$danger-100: #fbdddd;
$danger-200: #f7bcbc;
$danger-300: #f39a9a;
$danger-400: #ef7979;
$danger-500: #eb5757;
$danger-600: #bc4646;
$danger-700: #8d3434;
$danger-800: #5e2323;
$danger-900: #2f1111;
$danger: $danger-500;

$warning-100: #fcf4db;
$warning-200: #fae9b7;
$warning-300: #f7df94;
$warning-400: #f5d470;
$warning-500: #f2c94c;
$warning-600: #c2a13d;
$warning-700: #91792e;
$warning-800: #61501e;
$warning-900: #30280f;
$warning: $warning-500;

$success-100: #d4efdf;
$success-200: #a9dfbf;
$success-300: #7dcea0;
$success-400: #52be80;
$success-500: #27ae60;
$success-600: #1f8b4d;
$success-700: #17683a;
$success-800: #104626;
$success-900: #082313;
$success: $success-500;

$purple-100: #e0d8f1;
$purple-200: #B39DDB;
$purple-300: #9575CD;
$purple-400: #7E57C2;
$purple-500: #673AB7;
$purple-600: #5E35B1;
$purple-700: #512DA8;
$purple-800: #4527A0;
$purple-900: #311B92;
$purple-1000: #603BAF;
$purple-1100: #9747FF;
$royal: $purple-600;