.dx-state-disabled .dx-widget,
.dx-state-disabled.dx-widget {
    opacity: 1;
}

.dx-radiobutton.dx-state-disabled {
    opacity: 1;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending).dx-command-edit .dx-link.dx-state-disabled,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-datagrid-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-command-edit .dx-link.dx-state-disabled,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td:not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-command-edit .dx-link.dx-state-disabled {
    opacity: 1;
}

.dx-state-disabled .dx-widget,
.dx-state-disabled.dx-widget {
    opacity: 1;
}

.dx-texteditor.dx-editor-filled.dx-state-disabled,
.dx-texteditor.dx-editor-filled.dx-state-readonly,
.dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover {
    background-color: rgba(248, 248, 248, 1);
}